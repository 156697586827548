<template>
  <div class="waterfall" ref="waterfall" :style="{ paddingTop: waterfallListOffsetTop + 'px' }">
    <ul class="list" :class="{ 'is-show': selectedList.length > 0 }" :style="{
      width: listWidth,
      height: listHeight,
    }">
      <li class="list-item" v-for="(item, index) in listForUI" :key="index" :style="{
        height: item.height + 'px',
        left: item.left + 'px',
        top: item.top + 'px',
      }">
        <div class="poster" :style="{ height: item.height - titleHeight + 'px' }">
          <el-image lazy :src="item.poster && $store.state.assetsUrl + item.poster" alt=""
            @click="toggleSelected(selectedList.length > 0, item)"></el-image>
        </div>
        <div class="btns">
          <template v-for="(v, i) in operateBtns">
            <span :key="i" :style="{ background: v.background || '#353535' }"
              v-if="v.showStatus.indexOf(item.status) > -1" @click="v.handleClick(item, index)" :title="v.label"><img
                :src="v.icon" :alt="v.label" /></span>
          </template>
        </div>
        <div class="status-list">
          <span v-if="item.status == 1" class="status-item ing"></span>
          <span v-if="item.status == 2" class="status-item failed"></span>
          <span v-if="item.status == 3" class="status-item upstair"></span>
          <span v-if="item.status == 4" class="status-item downstair"></span>
        </div>
        <div class="resource-name" :title="item.resourceName" :style="{
          height: titleHeight + 'px',
          lineHeight: titleHeight + 'px',
        }">
          {{ item.resourceName }}
        </div>
      </li>
    </ul>
    <el-empty :image="EmptyIcon" v-show="listForUI.length == 0">
      <el-button type="primary" @click="goPost">去发布一个吧</el-button>
    </el-empty>
  </div>
</template>

<script>
import EmptyIcon from "../../assets/imgs/empty_icon.png";
import CallbackIcon from "../../assets/imgs/resource_callback.png"; // 撤回图标
import DeleteIcon from "../../assets/imgs/resource_delete.png"; // 删除图标
import DownstairIcon from "../../assets/imgs/resource_downstair.png"; // 下架图标
import RePostIcon from "../../assets/imgs/resource_repost.png"; // 转审核图标
import EditIcon from "../../assets/imgs/resource_edit.png"; // 编辑图标
import { debounce } from "../../utils";
import { mapState } from "vuex";
export default {
  name: "WaterfallList",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      EmptyIcon,
      CallbackIcon,
      DeleteIcon,
      DownstairIcon,
      RePostIcon,
      originData: [],
      columnsHeight: [], // 每个列的当前高度
      columnsMinHeightIndex: 0, // 当前高度最小的列
      waterfallWidth: window.innerWidth - 210, // 页面总宽度 - 侧边栏的210
      perColumnWidth: 235, // 每列的宽度
      gapWidth: 25, // 列之间的间隔
      gapHeight: 40, // 列之间的间隔
      titleHeight: 32, // 标题的高度间隔
      minWaterFallWidth: 300, // 定义最小的瀑布流宽度
      selectedList: [],
    };
  },
  computed: {
    ...mapState(["waterfallListOffsetTop"]),
    operateBtns() {
      return [
        {
          label: "撤回",
          // background: "#e38138",
          icon: CallbackIcon,
          showStatus: [1],
          handleClick: (item, index) => {
            this.withdrawFunc(item, index);
          },
        },
        {
          label: "删除",
          // background: "#cf423b",
          icon: DeleteIcon,
          showStatus: [2, 4],
          handleClick: (item, index) => {
            this.deleteFunc(item, index);
          },
        },
        {
          label: "下架",
          // background: "#e38138",
          icon: DownstairIcon,
          showStatus: [3],
          handleClick: (item, index) => {
            this.downstairFunc(item, index);
          },
        },
        {
          label: "编辑",
          // background: "#e38138",
          icon: EditIcon,
          showStatus: [2, 4],
          handleClick: (item, index) => {
            this.editFunc(item, index);
          },
        },
        {
          label: "重新发布",
          // background: "#0f5bff",
          icon: RePostIcon,
          showStatus: [2, 4],
          handleClick: (item, index) => {
            this.repostFunc(item, index);
          },
        },
      ];
    },
    listWidth() {
      return (
        this.columnsHeight.length * (this.perColumnWidth + this.gapWidth) -
        this.gapWidth +
        "px"
      );
    },
    listHeight() {
      return Math.max(...this.columnsHeight) + "px";
    },
    // 页面元素重新定位
    listForUI() {
      return this.data.map((v, i) => {
        // console.log(this.columnsHeight);
        // 缩放系数 = 原始宽度 / 每列展示宽度(即元素宽度)
        let scaleCoefficient = v.naturalWidth / this.perColumnWidth;
        // 元素高度 = 原始高度 / 缩放系数
        v.height = v.naturalHeight / scaleCoefficient + this.titleHeight;

        if (v.height - this.titleHeight > 500)
          v.height = 500 + this.titleHeight; // 最高为500px

        // 获取当前列的高度
        let columnHeight = this.columnsHeight[this.columnsMinHeightIndex];
        // 当前列原始高度 + 间隔
        v.top = columnHeight;
        // left值 = 当前列索隐 * 每列宽度 + 间隔
        v.left =
          this.columnsMinHeightIndex * (this.perColumnWidth + this.gapWidth);

        // 当前列高 + 新增元素高度
        let lastHeight = this.columnsHeight[this.columnsMinHeightIndex];
        let currColumnHeight = lastHeight + v.height + this.gapHeight;
        this.columnsHeight.length == 0 ? this.columnsHeight.push(currColumnHeight) : this.columnsHeight.splice(
          this.columnsMinHeightIndex,
          1,
          currColumnHeight
        );

        // 当前列高最小值
        let minHeight = Math.min(...this.columnsHeight) || 0;
        // 找到最小值的列
        this.columnsMinHeightIndex = this.columnsHeight.indexOf(minHeight);

        return v;
      });
    },
  },
  methods: {
    // 计算列数并重新分配列
    calcColumnCount() {
      this.waterfallWidth = this.$refs.waterfall.clientWidth; // 根据页面大小重新计算列数
      let columnCount;

      // 如果当前页面的宽度小于最小宽度，默认展示1列
      // 否则根据列宽和间隔计算
      if (this.waterfallWidth <= this.minWaterFallWidth) {
        columnCount = 1;
      } else {
        columnCount = Math.floor(
          (this.waterfallWidth + this.gapWidth) /
          (this.perColumnWidth + this.gapWidth)
        );
      }
      let newColumnsHeight = [];
      for (let i = 0; i < columnCount; i++) {
        newColumnsHeight.push(0);
      }

      this.columnsHeight = newColumnsHeight;
      this.columnsMinHeightIndex = 0;
    },
    // 检测页面变化
    windowResize: debounce(
      function (e) {
        this.calcColumnCount();
      },
      1000,
      false
    ),

    // 跳转发布页面
    goPost() {
      this.$router.push("/redirect/resources/post?source=post");
    },
    // 出发按钮方法的统一入口
    triggerClick(item, index) {
      if (typeof item.handleClick == "function") {
        item.handleClick(this.selectedList, item, index);
      }
    },
    // 我的发布，如果已上架，可以直接查看详情
    toggleSelected(canUse, item) {
      if (!canUse && item.status == 3) return window.open("/resources/detail?id=" + item.orId, "_blank");
    },
    resetSelected() {
      this.selectedList = [];
      this.calcColumnCount();
    },
    // 用户操作数据
    /**
     * @param {Object} item 数据
     * @param {Number} index 索引
     */
    withdrawFunc(item, index) {
      this.$confirm("您将要撤回当前资源，是否继续", "警告", {
        type: "warning",
      }).then(() => {
        this.$post("/resources/withdraw", {
          id: item.orId,
        }).then((res) => {
          if (res.code != 200) return this.$message.error(res.message);

          item.status = 4;
          this.$message.success("撤回成功");
        });
      });
    },
    deleteFunc(item, index) {
      this.$confirm("您将要删除当前资源，是否继续", "警告", {
        type: "warning",
      }).then(() => {
        this.$post("/resources/delete", {
          ids: [item.orId],
        }).then((res) => {
          if (res.code != 200) return this.$message.error(res.message);

          this.data.splice(index, 1);
          this.$message.success("删除成功");
        });
      });
    },
    downstairFunc(item, index) {
      this.$confirm("您将要下架当前资源，是否继续", "警告", {
        type: "warning",
      }).then(() => {
        this.$post("/resources/downstair", {
          id: item.orId,
        }).then((res) => {
          if (res.code != 200) return this.$message.error(res.message);

          item.status = 4;
          this.$message.success("下架成功");
        });
      });
    },
    // 驳回或下架的时候重新上架
    repostFunc(item, index) {
      this.$confirm("此操作将使资源重新进入审核，是否继续", "警告", {
        type: "warning",
      }).then(() => {
        this.$post("/resources/repost", {
          id: item.orId,
        }).then((res) => {
          if (res.code != 200) return this.$message.error(res.message);

          item.status = 1;
          this.$message.success("转审核成功");
        });
      });
    },
    // 驳回或下架时候重新编辑
    editFunc(item, index) {
      this.$router.push({
        path: "/resources/post",
        query: {
          id: item.id,
        },
      });
    },
  },
  mounted() {
    this.calcColumnCount();
    window.addEventListener("resize", this.windowResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.windowResize);
  },
  watch: {
    data(newV, oldV) {
      // if (newV.length != oldV.length) {
      this.calcColumnCount();
      // }
    },
    columnsHeight(newV) {
      // console.log(newV)
    },
  },
};
</script>

<style lang="scss" scoped>
.waterfall {
  margin-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1575px;
  margin: 0 auto;

  .list {
    position: relative;
    margin: 0 auto;

    .list-item {
      width: 235px;
      position: absolute;
      cursor: pointer;

      .poster {
        background-color: rgb(41, 45, 49);
        width: 100%;
        transition: 0.5s;
        max-height: 500px;
        overflow: hidden;

        .el-image {
          display: block;
        }

        img {
          width: 100%;
          display: block;
        }
      }

      .select,
      .btns,
      .resource-name {
        transition: 0.5s;
        z-index: -1;
        opacity: 0;
      }

      .select {
        position: absolute;
        right: 5px;
        top: 5px;

        /deep/.el-checkbox__input:not(.is-checked) {
          .el-checkbox__inner {
            background-color: transparent;
          }
        }

        /deep/.el-checkbox__inner {
          border-radius: 50%;
        }

        /deep/.el-checkbox__label {
          display: none;
        }
      }

      .btns {
        position: absolute;
        bottom: 37px;
        width: 100%;
        height: 36px;
        padding: 0 10px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 36px;
          height: 36px;
          background-color: rgba(0, 0, 0, 0.8);
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 18px;
            height: 18px;
          }

          &:hover {
            background-color: #000 !important;
          }
        }
      }

      .resource-name {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0 25px 0 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.2);
      }

      .status-list {
        position: absolute;
        top: 5px;
        left: 5px;
        width: 10px;
        height: 10px;
        z-index: 2;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .status-item {
          display: inline-block;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          opacity: 0.9;
          cursor: pointer;

          &.ing {
            background-color: #0f5bff;
          }

          &.failed {
            background-color: #cf423b;
          }

          &.upstair {
            background-color: #6abd4c;
          }

          &.downstair {
            background-color: #e38138;
          }
        }
      }
    }

    &.is-show,
    .list-item:hover {

      .btns,
      .resource-name,
      .select,
      .poster {
        z-index: 1;
        opacity: 1;
        transform: scale(0.98);
      }
    }
  }

  .select-confirm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0;
    bottom: -100%;
    align-items: center;
    width: 100%;
    height: 56px;
    padding: 0 40px;
    background-color: #0f5bff;
    color: #fff;
    transition: 0.5s;
    z-index: 999;

    .select-info,
    .select-funcs-item {
      padding: 5px 10px;

      i {
        font-weight: bold;
        margin-right: 10px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &.is-show {
      bottom: 0;
    }
  }
}
</style>
