
<template>
  <div class="resource" v-infinite-scroll="getList" :infinite-scroll-disabled="loadDisabled"
    :infinite-scroll-distance="50">
    <waterfall-post-list ref="waterfall" :data="list"></waterfall-post-list>
    <p class="load-tips" v-if="loading">加载中...</p>
    <p class="load-tips" v-if="finished">没有多了</p>
  </div>
</template>

<script>
import { debounce, generateListQuery, parseTime, setOptions } from "../../utils/index";
import WaterfallPostList from "../../components/resources/WaterfallPostList.vue";
import { getMineResources } from "../../api/resources";
export default {
  name: "ResourcesList",
  data() {
    return {
      list: [],
      listQuery: { ...generateListQuery() },
      finished: false,
      loading: false,
    };
  },
  computed: {
    status() {
      return this.$route.query.status || "";
    },
    type() {
      return this.$route.query.type || "";
    },
    category() {
      return this.$route.params.category || "";
    },
    categoryList() {
      return setOptions("category_list");
    },
    baseUrl() {
      return this.$store.state.baseUrl;
    },
    loadDisabled() {
      return this.loading || this.finished;
    },
  },
  components: {
    WaterfallPostList,
  },
  created() {
    this.getList();
  },
  methods: {
    parseTime,
    reGetList() {
      this.listQuery.pageNow = 1;
      this.list = [];
      this.getList();
    },
    getList: debounce(function () {
      this.loading = true;

      getMineResources({
        ...this.listQuery,
        status: this.status,
        category: this.category,
        typeId: this.type,
      }).then((res) => {
        if (res.code != 200) return this.$message.error(res.message);
        this.list = this.list.concat(res.data.list);
        this.finished = res.data.finished;
        this.loading = false;
        this.listQuery.pageNow++;
      });
    }, 50, false),
  },
  mounted() { },
  watch: {
    category(newV) {
      this.reGetList();
    },
    type(newV) {
      this.reGetList();
    },
    status(newV) {
      this.listQuery.status = newV;
      this.reGetList();
    }
  }
};
</script>

<style lang="scss" scoped>
.resource {
  padding-left: 20px;
  padding-right: 20px;

  .load-tips {
    text-align: center;
    color: #909399;
    padding: 10px 20px;
    font-size: 14px;
  }

  .filter {
    margin-top: 20px;
    padding: 0 20px;

    .el-form-item {
      margin-bottom: 0;
    }
  }
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-button,
::-webkit-scrollbar,
::-webkit-scrollbar-corner {
  width: 0 !important;
}
</style>
